import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

$(document).foundation();

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// If you are using slick slider load custom carousel
//require('./carousel');

// If you are using vertical timeline load the js
//require('./vertical-timeline');

// loads script that changes tables to foundation columns
//require('./table-columns');

$(function() {
    let url = window.location.href;
    $('#side-bar a').each(function() {
        if (url == (this.href)) {
            $(this).closest('li').addClass('active');
            $(this).closest('li').parent().addClass('is-active');
            $(this).closest('li').parent().css('display', '')
        }
    });
});